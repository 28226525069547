/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { connect } from 'react-redux'

import Feature from '@modules/utils/feature'

import { getConfigForKey } from '@/selectors'

import LaunchdarklyWrapper from './LaunchdarklyWrapper'

import type { ComponentType } from 'react'
import type { StateProps, ConsumerProps } from './types'

const mapStateToProps = (state): StateProps => ({
  clientSideID: getConfigForKey(state, Feature.launchdarklyId),
  launchDarklyClientInitializationTimeoutSeconds: getConfigForKey(
    state,
    'LAUNCHDARKLY_CLIENT_INITIALIZATION_TIMEOUT_SECONDS',
  ),
})

const Wrapper = connect<StateProps, null, ConsumerProps>(mapStateToProps)(LaunchdarklyWrapper)

export function withLDWrapper<P>(Component: ComponentType<P>) {
  const ComponentWithWrapper = (props) => (
    <Wrapper>
      <Component {...props} />
    </Wrapper>
  )
  const displayName = Component.displayName || Component.name || 'Component'
  ComponentWithWrapper.displayName = `WithLDWrapper(${displayName})`
  return ComponentWithWrapper
}
