/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FormattedMessage } from 'react-intl'
import React, { Fragment, useEffect, useRef } from 'react'

import { EuiConfirmModal, EuiLoadingSpinner, EuiSwitch, EuiText } from '@elastic/eui'

import { asAjaxRequestError } from '@modules/utils/ajax'
import { addToast } from '@modules/cui/Toasts'
import type { OrganizationRequest } from '@modules/cloud-api/v1/types'

import { EditUserAuthenticationFormRow } from '../components/EditUserAuthenticationFormRow'

import { errorMessages } from './messages'

import type { Props } from './types'

export const LoginEnforcement = ({
  organization,
  organizationId,
  fetchOrganizationIfNeeded,
  updateLoginEnforcement,
  updateOrganizationRequest,
}: Props) => {
  const enforceAuthenticationMethod =
    organization?.enforce_authentication_method as OrganizationRequest['enforce_authentication_method']

  const mounted = useRef(false)

  useEffect(() => {
    if (!organization) {
      fetchOrganizationIfNeeded(organizationId)
    }
  })

  const [isSwitchFromSocialToSsoEnforcement, setIsSwitchFromSocialToSsoEnforcement] =
    React.useState(false)

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return
    }

    if (updateOrganizationRequest.isDone) {
      addToast({
        family: 'organization.security.edit-user-authentication.updating-login-enforcement',
        color: 'success',
        iconType: 'check',
        title:
          enforceAuthenticationMethod === 'sso' ? (
            <FormattedMessage
              id='organization.security.edit-user-authentication.sso-enforcement-updated'
              defaultMessage='SSO enforcement has been enabled'
            />
          ) : (
            <FormattedMessage
              id='organization.security.edit-user-authentication.switch-off-sso-enforcement'
              defaultMessage='SSO enforcement has been disabled'
            />
          ),
      })
      setIsSwitchFromSocialToSsoEnforcement(false)
      return
    }

    if (updateOrganizationRequest.error) {
      const errorCode = asAjaxRequestError(updateOrganizationRequest.error)
        ?.body?.errors?.map((err) => err.code)
        ?.pop()

      const errorMessage = (errorCode && errorMessages[errorCode]) || errorMessages.default

      addToast({
        family: 'organization.security.edit-user-authentication.updating-login-enforcement',
        color: 'danger',
        iconType: 'alert',
        title: <FormattedMessage {...errorMessage} />,
      })
      return
    }
  }, [updateOrganizationRequest.isDone, updateOrganizationRequest.error])

  const onToggleSsoEnforcement = () => {
    if (enforceAuthenticationMethod === 'social') {
      setIsSwitchFromSocialToSsoEnforcement(true)
      return
    }

    onUpdateLoginEnforcement()
  }

  const onUpdateLoginEnforcement = () => {
    updateLoginEnforcement({
      enforce_authentication_method: enforceAuthenticationMethod === 'sso' ? null : 'sso',
    })
  }

  return (
    <Fragment>
      <EditUserAuthenticationFormRow
        label={
          <FormattedMessage
            id='organization.security.edit-user-authentication.sso-enforcement'
            defaultMessage='Enforce SAML SSO'
          />
        }
        valueElement={
          <Fragment>
            <EuiSwitch
              label={
                <FormattedMessage
                  id='organization.security.edit-user-authentication.sso-enforcement-description'
                  defaultMessage='When enabled, users must log in using SSO. They can no longer use credentials or Google/Microsoft login to access your organization.'
                />
              }
              checked={enforceAuthenticationMethod === 'sso'}
              onChange={onToggleSsoEnforcement}
            />
            {updateOrganizationRequest.inProgress && (
              <EuiLoadingSpinner size='m' style={{ marginLeft: '10px' }} />
            )}
          </Fragment>
        }
        hasLabelPadding={false}
      />
      {isSwitchFromSocialToSsoEnforcement && (
        <SwitchFromSocialToSsoEnforcementModal
          onCancel={() => setIsSwitchFromSocialToSsoEnforcement(false)}
          onConfirm={onUpdateLoginEnforcement}
          isLoading={updateOrganizationRequest.inProgress}
        />
      )}
    </Fragment>
  )
}

const SwitchFromSocialToSsoEnforcementModal = ({ onCancel, onConfirm, isLoading }) => (
  <EuiConfirmModal
    onCancel={onCancel}
    onConfirm={onConfirm}
    isLoading={isLoading}
    title={
      <FormattedMessage
        id='organization.security.edit-user-authentication.confirmation-switching-to-SSO.title'
        defaultMessage='Enforce SAML SSO?'
      />
    }
    cancelButtonText={
      <FormattedMessage
        id='organization.security.edit-user-authentication.confirmation-switching-to-SSO.cancel-button'
        defaultMessage='Cancel'
      />
    }
    confirmButtonText={
      <FormattedMessage
        id='organization.security.edit-user-authentication.confirmation-switching-to-SSO.confirm-button'
        defaultMessage='Continue'
      />
    }
  >
    <EuiText>
      <FormattedMessage
        id='organization.security.edit-user-authentication.confirmation-switching-to-SSO.description'
        defaultMessage='By enforcing SAML SSO, your organization members will only be able to log in using your identity provider. Other login methods will be disabled.'
      />
    </EuiText>
  </EuiConfirmModal>
)
