/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useRef } from 'react'
import { useIntl } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiSplitPanel,
  withEuiTheme,
  type WithEuiThemeProps,
} from '@elastic/eui'
import {
  AreaSeries,
  Axis,
  Chart,
  DARK_THEME,
  LIGHT_THEME,
  LineSeries,
  Position,
  ScaleType,
  Settings,
  timeFormatter,
  Tooltip,
  TooltipTable,
  type PointerEvent,
} from '@elastic/charts'

import { MetricTitle } from '@modules/auto-ops-components/MetricTitle/MetricTitle'
import { ChartCallout } from '@modules/auto-ops-components/ChartCallout/ChartCallout'
import { bytesToGigabytes, generateCallout, numberFormat } from '@modules/autoops-lib/util'
import type { MetricItem } from '@modules/autoops-api/types'

const baseTheme = {
  LIGHT: LIGHT_THEME,
  DARK: DARK_THEME,
}

type ElasticsearchChartsProps = WithEuiThemeProps & { data: Record<string, MetricItem | undefined> }

const yAxisStyle = {
  tickLine: { visible: true, size: 0, padding: 10 },
  tickLabel: {
    alignment: {
      horizontal: Position.Left,
      vertical: Position.Bottom,
    },
    padding: 0,
    offset: { x: 0, y: 0 },
  },
}

const calculateDomain = (item: MetricItem | undefined) => {
  if (!item) {
    return { min: 0, max: 0 }
  }

  const data = item.data ?? []
  const values = data.map((unit) => unit[1])

  return { min: Math.min(...values), max: Math.max(...values) }
}

const ElasticsearchCharts = ({ data, theme }: ElasticsearchChartsProps) => {
  const { formatMessage } = useIntl()
  const {
    search_latency,
    search_rate,
    index_rate,
    index_latency,
    search_vcu,
    ingest_vcu,
    ml_vcu,
    storage_retained,
  } = data
  const hasSearchLatency = search_latency !== undefined && search_latency.error === null
  const hasSearchRate = search_rate !== undefined && search_rate.error === null
  const hasIndexLatency = index_latency !== undefined && index_latency.error === null
  const hasIndexRate = index_rate !== undefined && index_rate.error === null
  const hasSearchVCU = search_vcu !== undefined && search_vcu.error === null
  const hasIngestVCU = ingest_vcu !== undefined && ingest_vcu.error === null
  const hasMlVCU = ml_vcu !== undefined && ml_vcu.error === null
  const hasStorage = storage_retained !== undefined && storage_retained.error === null
  const refSearch = useRef<Chart>(null)
  const refIndex = useRef<Chart>(null)
  const refVCU = useRef<Chart>(null)
  const refStorage = useRef<Chart>(null)
  const searchCallout = generateCallout('search', [search_latency, search_rate])
  const indexCallout = generateCallout('index', [index_latency, index_rate])
  const vcuCallout = generateCallout('vcu', [search_vcu, ingest_vcu, ml_vcu])
  const storageCallout = generateCallout('storage_retained', [storage_retained])

  const pointerUpdate = (event: PointerEvent) => {
    refSearch.current?.dispatchExternalPointerEvent(event)
    refIndex.current?.dispatchExternalPointerEvent(event)
    refVCU.current?.dispatchExternalPointerEvent(event)
    refStorage.current?.dispatchExternalPointerEvent(event)
  }

  const IndexChart = () => {
    const { min: rateMin, max: rateMax } = calculateDomain(index_rate)
    const { min: latencyMin, max: latencyMax } = calculateDomain(index_latency)

    return (
      <EuiFlexItem>
        <EuiSplitPanel.Outer grow={true} hasBorder={true}>
          <EuiSplitPanel.Inner>
            <MetricTitle
              title='Indexing Rate / Indexing Latency'
              infoContent={
                <div>
                  <strong>Indexing rate</strong>
                  <p>
                    Number of documents being indexed per second on all primary and replica shards
                    on the node.
                  </p>
                  <EuiSpacer size='m' />
                  <strong>Indexing latency</strong>
                  <p>
                    Average latency for indexing documents, which is the time it takes to index
                    documents divided by the number that were indexed in all primary and replica
                    shards hosted on the node.
                  </p>
                </div>
              }
            />
            <EuiSpacer size='xs' />
            <Chart size={{ height: '200px' }} ref={refIndex}>
              <Settings
                baseTheme={baseTheme[theme.colorMode]}
                noResults={
                  <ChartCallout
                    type={indexCallout.type}
                    title={formatMessage(indexCallout.title)}
                    message={indexCallout.message}
                  />
                }
                showLegend={false}
                pointerUpdateDebounce={0}
                onPointerUpdate={pointerUpdate}
              />

              <Tooltip
                headerFormatter={(pointerValue) =>
                  timeFormatter('DD-MM-YYYY hh:mm A')(pointerValue.value)
                }
              />

              <Axis
                id='bottom'
                position={Position.Bottom}
                showOverlappingTicks={true}
                labelFormat={timeFormatter('DD-MM hh:mm a')}
              />

              <Axis
                id='rate'
                groupId='rate'
                title='Indexing Rate'
                domain={{
                  min: rateMin,
                  max: rateMax,
                }}
                style={yAxisStyle}
                position={Position.Left}
                tickFormat={(d) => `${Number(d).toFixed(1)}/sec`}
              />

              <Axis
                id='latency'
                groupId='latency'
                title='Indexing Latency'
                domain={{
                  min: latencyMin,
                  max: latencyMax,
                }}
                style={yAxisStyle}
                position={Position.Right}
                tickFormat={(d) => `${Number(d).toFixed(0)} ms`}
              />

              {hasIndexRate && (
                <LineSeries
                  key='Indexing Rate'
                  id='Indexing Rate'
                  groupId='rate'
                  xScaleType={ScaleType.Time}
                  yScaleType={ScaleType.Linear}
                  xAccessor={0}
                  yAccessors={[1]}
                  data={index_rate.data ?? []}
                />
              )}
              {hasIndexLatency && (
                <LineSeries
                  key='Indexing Latency'
                  id='Indexing Latency'
                  groupId='latency'
                  xScaleType={ScaleType.Time}
                  yScaleType={ScaleType.Linear}
                  xAccessor={0}
                  yAccessors={[1]}
                  data={index_latency.data ?? []}
                />
              )}
            </Chart>
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      </EuiFlexItem>
    )
  }

  const SearchChart = () => {
    const { min: rateMin, max: rateMax } = calculateDomain(search_rate)
    const { min: latencyMin, max: latencyMax } = calculateDomain(search_latency)

    return (
      <EuiFlexItem>
        <EuiSplitPanel.Outer grow={true} hasBorder={true}>
          <EuiSplitPanel.Inner>
            <MetricTitle
              title='Search Rate / Search Latency'
              infoContent={
                <div>
                  <strong>Search rate</strong>
                  <p>
                    Number of search requests being executed per second on all shards hosted on the
                    node.
                  </p>
                  <EuiSpacer size='m' />
                  <strong>Search latency</strong>
                  <p>
                    Average latency for searching, which is the time it takes to execute searches
                    divided by the number of searches submitted to the node.
                  </p>
                </div>
              }
            />
            <EuiSpacer size='xs' />
            <Chart size={{ height: '200px' }} ref={refSearch}>
              <Settings
                baseTheme={baseTheme[theme.colorMode]}
                noResults={
                  <ChartCallout
                    type={searchCallout.type}
                    title={formatMessage(searchCallout.title)}
                    message={searchCallout.message}
                  />
                }
                showLegend={false}
                pointerUpdateDebounce={0}
                onPointerUpdate={pointerUpdate}
              />

              <Tooltip
                headerFormatter={(pointerValue) =>
                  timeFormatter('DD-MM-YYYY hh:mm A')(pointerValue.value)
                }
              />

              <Axis
                id='bottom'
                position={Position.Bottom}
                showOverlappingTicks={true}
                labelFormat={timeFormatter('DD-MM hh:mm a')}
              />

              <Axis
                id='rate'
                groupId='rate'
                title='Search Rate'
                domain={{
                  min: rateMin,
                  max: rateMax,
                }}
                style={yAxisStyle}
                position={Position.Left}
                tickFormat={(d) => `${Number(d).toFixed(1)}/sec`}
              />

              <Axis
                id='latency'
                groupId='latency'
                title='Search Latency'
                domain={{
                  min: latencyMin,
                  max: latencyMax,
                }}
                style={yAxisStyle}
                position={Position.Right}
                tickFormat={(d) => `${Number(d).toFixed(0)} ms`}
              />

              {hasSearchRate && (
                <LineSeries
                  key='Search Rate'
                  id='Search Rate'
                  groupId='rate'
                  xScaleType={ScaleType.Time}
                  yScaleType={ScaleType.Linear}
                  xAccessor={0}
                  yAccessors={[1]}
                  data={search_rate.data ?? []}
                />
              )}
              {hasSearchLatency && (
                <LineSeries
                  key='Search Latency'
                  id='Search Latency'
                  groupId='latency'
                  xScaleType={ScaleType.Time}
                  yScaleType={ScaleType.Linear}
                  xAccessor={0}
                  yAccessors={[1]}
                  data={search_latency.data ?? []}
                />
              )}
            </Chart>
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      </EuiFlexItem>
    )
  }

  return (
    <Fragment>
      <EuiFlexGroup gutterSize='l'>
        <EuiFlexItem>
          <EuiSplitPanel.Outer grow={true} hasBorder={true}>
            <EuiSplitPanel.Inner>
              <MetricTitle
                title='VCUs'
                infoContent='Amount of Virtual Compute Units (1 VCU contains 1GB of RAM and corresponding vCPU and local storage) allocated to each tier.'
              />
              <EuiSpacer size='xs' />
              <Chart size={{ height: '200px' }} ref={refVCU}>
                <Settings
                  baseTheme={baseTheme[theme.colorMode]}
                  noResults={
                    <ChartCallout
                      type={vcuCallout.type}
                      title={formatMessage(vcuCallout.title)}
                      message={vcuCallout.message}
                    />
                  }
                  showLegend={true}
                  legendPosition={Position.Right}
                  pointerUpdateDebounce={0}
                  onPointerUpdate={pointerUpdate}
                />

                <Tooltip
                  body={({ items }) => (
                    <TooltipTable
                      columns={[
                        {
                          id: 'color',
                          type: 'color',
                        },
                        {
                          id: 'label',
                          type: 'custom',
                          cell: ({ label }) => label,
                        },
                        {
                          id: 'value',
                          type: 'custom',
                          cell: ({ formattedValue }) => {
                            const value = numberFormat(Number(formattedValue), { decimals: 4 })

                            return `${value} VCUs`
                          },
                        },
                      ]}
                      items={items}
                    />
                  )}
                />

                <Axis
                  id='bottom'
                  position={Position.Bottom}
                  tickFormat={timeFormatter('DD-MM hh:mm a')}
                />

                <Axis id='left' position={Position.Left} />

                {hasSearchVCU && (
                  <LineSeries
                    key='Search'
                    id='Search'
                    xScaleType={ScaleType.Time}
                    yScaleType={ScaleType.Linear}
                    xAccessor={0}
                    yAccessors={[1]}
                    data={search_vcu.data ?? []}
                  />
                )}
                {hasIngestVCU && (
                  <LineSeries
                    key='Ingest'
                    id='Ingest'
                    xScaleType={ScaleType.Time}
                    yScaleType={ScaleType.Linear}
                    xAccessor={0}
                    yAccessors={[1]}
                    data={ingest_vcu.data ?? []}
                  />
                )}
                {hasMlVCU && (
                  <LineSeries
                    key='ML'
                    id='ML'
                    xScaleType={ScaleType.Time}
                    yScaleType={ScaleType.Linear}
                    xAccessor={0}
                    yAccessors={[1]}
                    data={ml_vcu.data ?? []}
                  />
                )}
              </Chart>
            </EuiSplitPanel.Inner>
          </EuiSplitPanel.Outer>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiSplitPanel.Outer grow={true} hasBorder={true}>
            <EuiSplitPanel.Inner>
              <MetricTitle
                title='Storage retained'
                infoContent='Amount of data that is being retained in long-term object storage (subject to data retention settings).'
              />
              <EuiSpacer size='xs' />
              <Chart size={{ height: '200px' }} ref={refStorage}>
                <Settings
                  baseTheme={baseTheme[theme.colorMode]}
                  noResults={
                    <ChartCallout
                      type={storageCallout.type}
                      title={formatMessage(storageCallout.title)}
                      message={storageCallout.message}
                    />
                  }
                  legendPosition={Position.Right}
                  pointerUpdateDebounce={0}
                  onPointerUpdate={pointerUpdate}
                />

                <Axis
                  id='bottom'
                  position={Position.Bottom}
                  tickFormat={timeFormatter('DD-MM hh:mm a')}
                />

                <Axis
                  id='left'
                  position={Position.Left}
                  tickFormat={(d) => `${Number(d).toFixed(4)} GB`}
                />

                {hasStorage && (
                  <AreaSeries
                    key='Storage retained'
                    id='Storage retained'
                    xScaleType={ScaleType.Time}
                    yScaleType={ScaleType.Linear}
                    xAccessor={0}
                    yAccessors={[1]}
                    data={(storage_retained.data ?? []).map(([x, y]) => [x, bytesToGigabytes(y)])}
                  />
                )}
              </Chart>
            </EuiSplitPanel.Inner>
          </EuiSplitPanel.Outer>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='l' />
      <EuiFlexGroup gutterSize='l'>
        <IndexChart />
        <SearchChart />
      </EuiFlexGroup>
    </Fragment>
  )
}

export default withEuiTheme(ElasticsearchCharts)
