/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Formik } from 'formik'
import { jsx } from '@emotion/react'
import { Fragment, useState } from 'react'

import {
  EuiButton,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiSpacer,
  EuiText,
  EuiTextArea,
} from '@elastic/eui'

import { getPemFormatCertificate } from '@modules/security-idp-lib'
import type { IdpConfiguration, IdpResponse } from '@modules/security-idp-api/types'
import { CuiFormField } from '@modules/cui/forms'
import { useOrganizationIdpMutation } from '@modules/security-idp-lib/hooks'
import { addToast } from '@modules/cui/Toasts'
import type { ApiErrorCollection } from '@modules/query/types'
import type { OrganizationRequest } from '@modules/cloud-api/v1/types'
import { CuiAlert } from '@modules/cui/Alert'

import { EditUserAuthenticationFormRow } from '../components/EditUserAuthenticationFormRow'

import { validateUserInput } from './lib'
import { updateIdpConfigErrorMessages } from './messages'

import type { IdpUserInput } from '../types'
import type { Props } from './types'
import type { ComponentType, ReactElement } from 'react'

const getUpdatedIdpConfiguration = (
  idpResponse: IdpResponse | undefined,
  values: IdpUserInput,
): IdpConfiguration => ({
  enabled: idpResponse?.configuration.enabled ?? true,
  login_identifier_prefix: values.loginIdentifierPrefix,
  saml_idp: {
    issuer: values.issuer,
    sso_url: values.ssoUrl,
    public_certificate: [Buffer.from(values.publicCertificate.trim(), 'utf-8').toString('base64')],
  },
})

export const UserProvidedConfiguration = ({
  organization,
  organizationId,
  idpResponse,
  updateLoginEnforcement,
  updateOrganizationRequest,
}: Props) => {
  const { formatMessage } = useIntl()
  const { mutate: updateOrganizationIdp, isLoading } = useOrganizationIdpMutation()
  const [configPendingEnforcementConfirmation, setConfigPendingEnforcementConfirmation] =
    useState<IdpConfiguration | null>(null)

  const enforceAuthenticationMethod =
    organization?.enforce_authentication_method as OrganizationRequest['enforce_authentication_method']

  const userConfigurationResponse = idpResponse?.configuration
  const savedPublicCertificate = userConfigurationResponse?.saml_idp.public_certificate[0]
  const initialFormValues: IdpUserInput = {
    loginIdentifierPrefix: userConfigurationResponse?.login_identifier_prefix || '',
    ssoUrl: userConfigurationResponse?.saml_idp.sso_url || '',
    issuer: userConfigurationResponse?.saml_idp.issuer || '',
    publicCertificate: getPemFormatCertificate(savedPublicCertificate || ''),
  }

  const onSubmit = (values: IdpUserInput) => {
    const config = getUpdatedIdpConfiguration(idpResponse, values)

    if (enforceAuthenticationMethod === 'sso') {
      return setConfigPendingEnforcementConfirmation(config)
    }

    onUpdateOrganizationIdp(config)
  }

  const onConfirmDisableEnforcement = (config: IdpConfiguration) => () => {
    updateLoginEnforcement({
      enforce_authentication_method: null,
    }).then(() => {
      setConfigPendingEnforcementConfirmation(null)
      return onUpdateOrganizationIdp(config)
    })
  }

  const onUpdateOrganizationIdp = (config) =>
    updateOrganizationIdp(
      {
        organizationId,
        idpConfiguration: config,
      },
      {
        onSuccess: () => {
          addToast({
            family: 'organization.security.edit-user-authentication.updating-idp-toast',
            color: 'success',
            iconType: 'check',
            title: (
              <FormattedMessage
                id='organization.security.edit-user-authentication.success-updating-idp-toast'
                defaultMessage='Configuration updated'
              />
            ),
          })
        },
        onError: (error: ApiErrorCollection) => {
          const errorCode = error.errors.pop()?.code

          addToast({
            family: 'organization.security.edit-user-authentication.updating-idp-toast',
            color: 'danger',
            iconType: 'alert',
            title: formatMessage(
              updateIdpConfigErrorMessages[errorCode || ''] ||
                updateIdpConfigErrorMessages.unknownError,
            ),
          })
        },
      },
    )

  return (
    <Fragment>
      <div>
        <EuiText color='subdued'>
          <FormattedMessage
            id='organization.security.user-authentication.provided-by-you'
            defaultMessage='PROVIDED BY YOU'
          />
        </EuiText>
        <EuiSpacer size='s' />
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
          validate={validateUserInput(formatMessage)}
        >
          {({ dirty }) => (
            <Form>
              <Row
                label={
                  <FormattedMessage
                    id='organization.security.user-authentication.idp-entity-id'
                    defaultMessage='Identity Provider Entity ID'
                  />
                }
                name='issuer'
                description={
                  <FormattedMessage
                    id='organization.security.user-authentication.idp-entity-id-description'
                    defaultMessage='The unique identifier of your identity provider that allows Elastic Cloud to verify the source of SAML assertions. This is the issuer your identity provider will send in SAML responses.'
                  />
                }
              />
              <Row
                label={
                  <FormattedMessage
                    id='organization.security.user-authentication.idp-sso-url'
                    defaultMessage='Identity Provider SSO URL'
                  />
                }
                description={
                  <FormattedMessage
                    id='organization.security.user-authentication.idp-sso-url-description'
                    defaultMessage='The URL from your identity provider that your users will be redirected to when logging in. This should be the HTTP-POST SAML binding of your identity provider.'
                  />
                }
                name='ssoUrl'
              />
              <Row
                label={
                  <FormattedMessage
                    id='organization.security.user-authentication.public-cert'
                    defaultMessage='Public x509 certificate'
                  />
                }
                description={
                  <FormattedMessage
                    id='organization.security.user-authentication.public-cert-description'
                    defaultMessage='This certificate is used to verify the authenticity and integrity of SAML assertions during the SSO process.'
                  />
                }
                name='publicCertificate'
                component={EuiTextArea}
              />
              <Row
                label={
                  <FormattedMessage
                    id='organization.security.user-authentication.login-id-prefix'
                    defaultMessage='Login identifier prefix'
                  />
                }
                description={
                  <FormattedMessage
                    id='organization.security.user-authentication.login-id-prefix-description'
                    defaultMessage='<p>The string used to differentiate your organization’s dedicated SSO login URL. For example, `cloud.elastic.co/sso/login/<b>myacmeorg</b>-12345`.</p>'
                    values={{ p: (content) => <p>{content}</p>, b: (content) => <b>{content}</b> }}
                  />
                }
                name='loginIdentifierPrefix'
              />
              <EuiSpacer />
              <EuiFlexGroup justifyContent='flexEnd'>
                <EuiButton
                  type='submit'
                  color='primary'
                  isLoading={isLoading || updateOrganizationRequest.inProgress}
                  disabled={!dirty}
                >
                  <FormattedMessage
                    id='organization.security.user-authentication.update-configuration'
                    defaultMessage='Update configuration'
                  />
                </EuiButton>
              </EuiFlexGroup>
            </Form>
          )}
        </Formik>
      </div>
      {configPendingEnforcementConfirmation && (
        <DisableIdpEnforcementModal
          onCancel={() => setConfigPendingEnforcementConfirmation(null)}
          onConfirm={onConfirmDisableEnforcement(configPendingEnforcementConfirmation)}
          isLoading={updateOrganizationRequest.inProgress}
        />
      )}
    </Fragment>
  )
}

const Row = ({
  label,
  name,
  description,
  component,
}: {
  label: ReactElement
  name: string
  description: ReactElement
  component?: ComponentType<any>
}) => (
  <EditUserAuthenticationFormRow
    label={label}
    valueElement={
      <CuiFormField
        name={name}
        component={component}
        fullWidth={true}
        helpText={description}
        aria-label={name}
      />
    }
  />
)

const DisableIdpEnforcementModal = ({ onCancel, onConfirm, isLoading }) => (
  <EuiConfirmModal
    onCancel={onCancel}
    onConfirm={onConfirm}
    isLoading={isLoading}
    title={
      <FormattedMessage
        id='organization.security.edit-user-authentication.confirmation-disable-idp-enforcement.title'
        defaultMessage='Update identity provider configuration?'
      />
    }
    cancelButtonText={
      <FormattedMessage
        id='organization.security.edit-user-authentication.confirmation-disable-idp-enforcement.cancel-button'
        defaultMessage='Cancel'
      />
    }
    confirmButtonText={
      <FormattedMessage
        id='organization.security.edit-user-authentication.confirmation-disable-idp-enforcement.confirm-button'
        defaultMessage='Disable SAML SSO enforcement and update configuration'
      />
    }
    data-test-id='disable-idp-enforcement-modal'
  >
    <EuiText>
      <FormattedMessage
        id='organization.security.edit-user-authentication.confirmation-disable-idp-enforcement.description'
        defaultMessage="By updating the identity provider configuration, <bold>SAML SSO enforcement will be disabled</bold>. Your organization's members will be able to log in using other methods while you validate that the SSO configuration works correctly."
        values={{
          bold: (content) => <b>{content}</b>,
        }}
      />
    </EuiText>
    <EuiSpacer size='m' />
    <CuiAlert type='warning'>
      <FormattedMessage
        id='organization.security.edit-user-authentication.confirmation-disable-idp-enforcement.warning'
        defaultMessage='You can enable SAML SSO enforcement again manually after you have validated your configuration.'
      />
    </CuiAlert>
  </EuiConfirmModal>
)
