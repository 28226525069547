/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { useHistory, useLocation } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { useContext } from 'react'

import { EuiButton, useEuiTheme } from '@elastic/eui'

import { outlineButtonStyle } from '@modules/access-management-components/styles'
import { ssoLoginUrl } from '@modules/auth/urls'
import AuthContext from '@modules/auth/context'

import { buildSignInLink, buildSignInQuery } from '@/lib/urlUtils'

const SsoSignUp = ({
  ssoLoginIdentifier,
  isFlowV2,
}: {
  ssoLoginIdentifier?: string
  isFlowV2: boolean | undefined
}) => {
  const euiThemeHook = useEuiTheme()
  const { setAuthContext } = useContext(AuthContext)
  const history = useHistory()

  const { search, pathname } = useLocation()
  const ssoLink = buildSignInLink(
    ssoLoginIdentifier ? `${ssoLoginUrl()}/${ssoLoginIdentifier}` : ssoLoginUrl(),
    buildSignInQuery({ search, withReferrer: false }),
  )

  return (
    <EuiButton
      iconType='key'
      css={css(
        {
          path: {
            fill: 'grey',
          },
        },
        isFlowV2 && outlineButtonStyle(euiThemeHook),
      )}
      color='primary'
      onClick={(e) => {
        e.preventDefault()
        setAuthContext({ method: 'byoidp' })
        history.push(ssoLink, { from: pathname })
      }}
    >
      <FormattedMessage id='login-form.sso' defaultMessage='SSO' />
    </EuiButton>
  )
}

export default SsoSignUp
