/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessages } from 'react-intl'

export const errorMessages = defineMessages({
  'organization.sso_enforcement_validate_session': {
    id: `organization.security.edit-user-authentication.update-login-enforcement-validate-session`,
    defaultMessage: `To avoid losing access to your account, you can only enable SSO enforcement after your next successful login with SSO.`,
  },
  default: {
    id: 'organization.security.edit-user-authentication.update-login-enforcement-error',
    defaultMessage: 'Error updating login enforcement. Changes were not applied. Try again.',
  },
})
