/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiListGroup, EuiPanel, EuiSpacer } from '@elastic/eui'

import DocLink from '@/components/DocLink'

import type { FunctionComponent, ReactElement } from 'react'

interface Props {
  hasBorder?: boolean
  showEcuMessage?: boolean
  title: ReactElement
}

const SuggestedContentPanel: FunctionComponent<Props> = ({ hasBorder, showEcuMessage, title }) => {
  const items = [
    {
      label: (
        <DocLink link='billingFAQ' favorSaasContext={true}>
          <FormattedMessage id='suggested-content.billing-faq' defaultMessage='Billing FAQs' />
        </DocLink>
      ),
      'data-test-id': 'suggested-content-panel.link',
    },
    {
      label: (
        <DocLink link='accountBilling' favorSaasContext={true}>
          <FormattedMessage
            id='suggested-content.billing-account-faq'
            defaultMessage='Your account and billing'
          />
        </DocLink>
      ),
      'data-test-id': 'suggested-content-panel.link',
    },
    {
      label: (
        <DocLink link='billingCalculationFAQ' favorSaasContext={true}>
          <FormattedMessage
            id='suggested-content.billing-calculation-faq'
            defaultMessage='How my bill is calculated'
          />
        </DocLink>
      ),
      'data-test-id': 'suggested-content-panel.link',
    },
    {
      label: (
        <DocLink link='stopBillingCharges' favorSaasContext={true}>
          <FormattedMessage
            id='suggested-content.billing.stop-charges'
            defaultMessage='How to stop charges for a deployment'
          />
        </DocLink>
      ),
      'data-test-id': 'suggested-content-panel.link',
    },
  ]

  if (showEcuMessage) {
    items.push({
      label: (
        <DocLink link='billingECU' favorSaasContext={true}>
          <FormattedMessage
            id='suggested-content.billing.ecus'
            defaultMessage='What are Elastic Consumption Units (ECU)'
          />
        </DocLink>
      ),
      'data-test-id': 'suggested-content-panel.link',
    })
  }

  return (
    <EuiPanel hasBorder={hasBorder} data-test-id='suggested-content-card' grow={false}>
      {title}

      <EuiSpacer size='m' />

      <EuiListGroup flush={true} listItems={items} />
    </EuiPanel>
  )
}

export default SuggestedContentPanel
