/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  mappingName: {
    id: 'organization.security.role-mapping.mapping-name',
    defaultMessage: 'Mapping name',
  },
  mappingNameLabel: {
    id: 'organization.security.role-mapping.mapping-name-label',
    defaultMessage: 'Name',
  },

  enableMapping: {
    id: 'organization.security.role-mapping.enable-role-mapping',
    defaultMessage: 'Enable mapping',
  },
  enableMappingExplanation: {
    id: 'organization.security.role-mapping.enable-role-mapping-explanation',
    defaultMessage:
      'When this toggle is enabled, the role mapping will take effect, otherwise it will be defined but disabled.',
  },
  roles: {
    id: 'organization.security.role-mapping.roles',
    defaultMessage: 'Roles',
  },
  rolesExplanation: {
    id: 'organization.security.role-mapping.roles-explanation',
    defaultMessage: 'Select the roles to assign as part of this mapping.',
  },
  roleMappingDetailsTitle: {
    id: 'organization.security.role-mapping.role-mapping-details-title',
    defaultMessage: 'Role mapping details',
  },
  editRolesButton: {
    id: 'organization.security.role-mapping.edit-roles-button',
    defaultMessage: 'Edit roles',
  },
  addRolesButton: {
    id: 'organization.security.role-mapping.add-roles-button',
    defaultMessage: 'Add roles',
  },
  roleAssignmentsFlyoutTitle: {
    id: 'organization.security.role-mapping.role-assignments-flyout-title',
    defaultMessage: 'Roles',
  },
  roleAssignmentsFlyoutCancelButton: {
    id: 'organization.security.role-mapping.role-assignments-flyout-cancel-button',
    defaultMessage: 'Cancel',
  },
  roleAssignmentsFlyoutSaveButton: {
    id: 'organization.security.role-mapping.role-assignments-flyout-save-button',
    defaultMessage: 'Save roles',
  },
  cancelButton: {
    id: 'organization.security.role-mapping.cancel-button',
    defaultMessage: 'Cancel',
  },
  saveButton: {
    id: 'organization.security.role-mapping.save-button',
    defaultMessage: 'Save',
  },
})
