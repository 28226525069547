/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  mappingRulesTitle: {
    id: `organization.security.role-mapping.mapping-rules-title`,
    defaultMessage: `Mapping rule`,
  },
  mappingRulesDescription: {
    id: `organization.security.role-mapping.mapping-rules-description`,
    defaultMessage: `Assign the roles defined in this mapping to users based on their <code>groups</code> attribute values in the Identity Provider SAML configuration.`,
  },
  noRulesDefined: {
    id: `organization.security.role-mapping.no-rules-defined`,
    defaultMessage: `You haven't defined a rule yet`,
  },
  noRulesDefinedDescription: {
    id: `organization.security.role-mapping.no-mapping-defined-description`,
    defaultMessage: `The mapping rule controls which users should be assigned roles.`,
  },
  addRules: {
    id: `organization.security.role-mapping.add-rules`,
    defaultMessage: `Create mapping rule`,
  },
  add: {
    id: `organization.security.role-mapping.add`,
    defaultMessage: `Add value`,
  },
  'ruleType-all': {
    id: `organization.security.role-mapping.rule-type-all`,
    defaultMessage: `All are true`,
  },
  'ruleType-any': {
    id: `organization.security.role-mapping.rule-type-any`,
    defaultMessage: `Any is true`,
  },
})
