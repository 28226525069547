/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { FormattedMessage, useIntl } from 'react-intl'
import { css, jsx } from '@emotion/react'

import { EuiButtonIcon, EuiPanel, EuiProgress, EuiSpacer, EuiStat, EuiText } from '@elastic/eui'

import { CuiRouterLink } from '@modules/cui/RouterButton'
import { CuiAlert } from '@modules/cui/Alert'
import CuiToggleablePopoverForClassComp from '@modules/cui/CuiToggleablePopoverForClassComp'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import type { CloudAppName } from '@modules/ui-types'
import { organizationBillingSubscriptionUrl } from '@modules/urls/organizations'
import {
  getMarketplaceDisplayName,
  isMarketplaceCustomer,
} from '@modules/billing-lib/billingDetails/utils'
import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'
import { useGetLineItemsV1 } from '@modules/billing-lib/hooks'
import type { LineItems } from '@modules/billing-api/customTypes'

import { billingSubscriptionUrl } from '@/apps/userconsole/urls'
import Percentage from '@/lib/Percentage'
import lightTheme from '@/lib/theme/light'
import { getConfigForKey } from '@/store'

import messagesBillingOverview from '../../messages'
import UsageStat from '../UsageStat'

import messages from './messages'
import { getActiveBalance } from './utils'

interface Props {
  organizationId: string
}

const PrepaidConsumptionOverviewCard = ({ organizationId }: Props) => {
  const billingDetails = useBillingDetails()
  const { formatMessage } = useIntl()
  const { data, isFetching, isError } = useGetLineItemsV1({
    pathParameters: { organization_id: organizationId },
  })

  const renderMarketplaceInfo = (): JSX.Element | null => {
    if (billingDetails.data && isMarketplaceCustomer(billingDetails.data)) {
      const channel = billingDetails.data.channel

      return (
        <EuiText size='s'>
          <FormattedMessage
            id='billing-overview.overview-card.marketplace-info'
            defaultMessage={`Billed through {channel} account`}
            values={{
              channel: getMarketplaceDisplayName(channel),
            }}
          />
        </EuiText>
      )
    }

    return null
  }

  const renderBalanceOverviewContent = (): JSX.Element => {
    const { available, remaining } = getActiveBalance(data as LineItems)

    if (isError) {
      return (
        <CuiAlert type='error' data-test-id='error-message'>
          <FormattedMessage {...messagesBillingOverview.genericErrorMessage} />
        </CuiAlert>
      )
    }

    return (
      <div data-test-id='balance-overview-card'>
        <EuiStat
          isLoading={isFetching}
          title={
            <UsageStat
              title={
                <CuiElasticConsumptionUnits value={remaining} unit='none' withSymbol={false} />
              }
              subText={<FormattedMessage {...messages.ecuUnit} />}
              data-test-id='balance-overview-card.remaining-balance'
            />
          }
          titleSize='l'
          description={
            <EuiText size='s'>
              <FormattedMessage {...messages.remainingBalance} />
            </EuiText>
          }
          descriptionElement='div'
        />

        <EuiSpacer size='s' />

        {!isFetching && (
          <EuiProgress
            color='primary'
            value={remaining}
            max={available}
            size='l'
            css={css({ maxWidth: 234 })}
            label={
              <EuiText size='xs' color={lightTheme.euiColorPrimary}>
                <FormattedMessage
                  {...messages.remaining}
                  values={{
                    remainingValue: (
                      <Percentage value={available === 0 ? 0 : remaining / available} />
                    ),
                  }}
                />
              </EuiText>
            }
          />
        )}

        <EuiSpacer size='xl' />

        {isFetching && <EuiSpacer size='xl' />}

        <EuiStat
          isLoading={isFetching}
          title={
            <UsageStat
              title={
                <CuiElasticConsumptionUnits value={available} unit='none' withSymbol={false} />
              }
              subText={<FormattedMessage {...messages.ecuUnit} />}
              data-test-id='balance-overview-card.initial-balance'
            />
          }
          titleSize='xs'
          description={
            <EuiText size='s'>
              <FormattedMessage {...messages.initialBalance} /> {renderInitialBalancePopover()}
            </EuiText>
          }
          descriptionElement='div'
        />
      </div>
    )
  }

  const renderBalancePopover = (): JSX.Element => (
    <CuiToggleablePopoverForClassComp
      toggleButton={(toggleButtonFunc) => (
        <EuiButtonIcon
          onClick={toggleButtonFunc}
          css={css({ marginLeft: 8 })}
          data-test-id='balance-overview-card.balance-popover-button'
          iconType='questionInCircle'
          display='empty'
          aria-label={formatMessage(messages.popoverActiveBalanceAriaLabel)}
        />
      )}
    >
      <EuiText
        css={css({ width: 300 })}
        data-test-id='balance-overview-card.balance-popover-content'
      >
        <FormattedMessage
          {...messages.balancePopover}
          values={{
            subscriptionLink: (content) => (
              <CuiRouterLink to={getSubscriptionLink()}>{content}</CuiRouterLink>
            ),
          }}
        />
      </EuiText>
    </CuiToggleablePopoverForClassComp>
  )

  const renderInitialBalancePopover = (): JSX.Element => (
    <CuiToggleablePopoverForClassComp
      toggleButton={(toggleButtonFunc) => (
        <EuiButtonIcon
          onClick={toggleButtonFunc}
          data-test-id='balance-overview-card.initial-balance-popover-button'
          iconType='questionInCircle'
          display='empty'
          aria-label={formatMessage(messages.popoverStartingBalanceAriaLabel)}
        />
      )}
    >
      <EuiText
        className='popoverContent'
        data-test-id='balance-overview-card.initial-balance-popover-content'
      >
        <FormattedMessage
          {...messages.initialBalancePopover}
          values={{
            subscriptionLink: (content) => (
              <CuiRouterLink to={getSubscriptionLink()}>{content}</CuiRouterLink>
            ),
          }}
        />
      </EuiText>
    </CuiToggleablePopoverForClassComp>
  )

  const getSubscriptionLink = (): string => {
    const appName: CloudAppName = getConfigForKey(`APP_NAME`) || 'userconsole' // fallback to 'userconsole' just in case

    const subscriptionLink = {
      userconsole: billingSubscriptionUrl(),
      adminconsole: organizationBillingSubscriptionUrl(organizationId),
    }[appName]

    return subscriptionLink
  }

  return (
    <EuiPanel hasShadow={false} paddingSize='l' hasBorder={true} borderRadius='m' grow={false}>
      <EuiSpacer size='xl' />

      <EuiText size='m'>
        <h6>
          <FormattedMessage {...messages.plan} />
        </h6>
      </EuiText>

      <EuiText size='s' css={css({ display: 'flex', alignItems: 'center' })}>
        <h2 data-test-id='balance-overview-card.title'>
          <FormattedMessage {...messages.prepaidBalance} />
        </h2>
        {renderBalancePopover()}
      </EuiText>

      {renderMarketplaceInfo()}

      <EuiSpacer size='l' />

      {renderBalanceOverviewContent()}

      <EuiSpacer size='xl' />
    </EuiPanel>
  )
}

export default PrepaidConsumptionOverviewCard
